import { QueryCacheLifecycleApi } from "@reduxjs/toolkit/dist/query/endpointDefinitions"
import {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
  FetchBaseQueryMeta,
} from "@reduxjs/toolkit/dist/query/react"
import SocketFactory, { ISocket } from "../../../services/SocketFactory"
import {
  AdlAlarm,
  AdlAlarmNtcThreshold,
  AdlAlarmFilteredThreshold,
} from "./entities"
import {
  emitAlarm,
  getADLAlarmContent,
  NtcThresholdIcon,
  AdlAlarmIcon,
  FilterdThresholdIcon,
} from "./utils"

export const onAdlAlarmCacheEntryAdded = async (
  companyId: number,
  {
    updateCachedData,
    cacheDataLoaded,
    cacheEntryRemoved,
  }: QueryCacheLifecycleApi<
    number,
    BaseQueryFn<
      string | FetchArgs,
      unknown,
      FetchBaseQueryError,
      {},
      FetchBaseQueryMeta
    >,
    void,
    "adlApi"
  >
) => {
  const WS_ADL_ALARM_EVENT = `WS2CLIENT/ADL_ALARM/${companyId}`

  let socket!: ISocket

  try {
    socket = SocketFactory.create()

    socket.socket.on(WS_ADL_ALARM_EVENT, async (data: AdlAlarm) => {
      if (!data) return

      emitAlarm({
        title: "Alteração na Tendência de Vibração",
        backgroundColor: "#FF4500",
        icon: AdlAlarmIcon,
        content: getADLAlarmContent(data),
      })
    })

    await cacheEntryRemoved
  } catch (error) {
    console.error(error)
  }

  await cacheEntryRemoved
  socket.socket.off(WS_ADL_ALARM_EVENT)
}

export const onNtcThresholdAlarmCacheEntryAdded = async (
  companyId: number,
  {
    updateCachedData,
    cacheDataLoaded,
    cacheEntryRemoved,
  }: QueryCacheLifecycleApi<
    number,
    BaseQueryFn<
      string | FetchArgs,
      unknown,
      FetchBaseQueryError,
      {},
      FetchBaseQueryMeta
    >,
    void,
    "adlApi"
  >
) => {
  const WS_ALARM_EVENT = `WS2CLIENT/ADL_NTC_ALARM/${companyId}`

  let socket!: ISocket

  try {
    socket = SocketFactory.create()

    socket.socket.on(WS_ALARM_EVENT, async (data: AdlAlarmNtcThreshold) => {
      if (!data) return

      const value = (Math.trunc(data.value * 100) / 100)
        .toString()
        .replace(".", ",")

      emitAlarm({
        title: "Temperatura Acima do Limite Operacional",
        backgroundColor: "#FF4500",
        icon: NtcThresholdIcon,
        content: {
          Valor: `${value} °C`,
          Local: data.sensorName,
          Data: new Date(data.time * 1000).toLocaleString("pt-BR"),
        },
      })
    })

    await cacheEntryRemoved
  } catch (error) {
    console.error(error)
  }

  await cacheEntryRemoved
  socket.socket.off(WS_ALARM_EVENT)
}

export const onFilteredThresholdAlarmCacheEntryAdded = async (
  companyId: number,
  {
    updateCachedData,
    cacheDataLoaded,
    cacheEntryRemoved,
  }: QueryCacheLifecycleApi<
    number,
    BaseQueryFn<
      string | FetchArgs,
      unknown,
      FetchBaseQueryError,
      {},
      FetchBaseQueryMeta
    >,
    void,
    "adlApi"
  >
) => {
  const WS_ALARM_EVENT = `WS2CLIENT/ADL_FILTERED_ALARM/${companyId}`

  let socket!: ISocket

  try {
    socket = SocketFactory.create()

    socket.socket.on(
      WS_ALARM_EVENT,
      async (data: AdlAlarmFilteredThreshold) => {
        if (!data) return

        emitAlarm({
          title: "Vibração Acima do Limite Operacional",
          backgroundColor: "#EAAA08",
          icon: FilterdThresholdIcon,
          content: {
            Local: data.sensorName,
            Data: new Date(data.time * 1000).toLocaleString("pt-BR"),
          },
        })
      }
    )

    await cacheEntryRemoved
  } catch (error) {
    console.error(error)
  }

  await cacheEntryRemoved
  socket.socket.off(WS_ALARM_EVENT)
}
