import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"
import { IGatewayDataTable } from "../features/gateways/gateways.interfaces"
import { getAccessToken } from "../../utils/getAccessToken"

export const gatewaysApi = createApi({
  reducerPath: "gateways",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BACKEND_THOR_URL,
    prepareHeaders: (headers) => {
      const token = getAccessToken()
      if (token) {
        headers.set("Authorization", `Bearer ${token}`)
      }
      return headers
    },
  }),
  tagTypes: ["Gateway"],
  endpoints: (build) => ({
    fetchGateways: build.query<IGatewayDataTable[], void>({
      query: () => ({
        url: "/v1/gateways/prov",
        params: {
          offset: 0,
          limit: 60,
          order_by: "created_at",
          sort_by: "asc",
        },
        method: "GET",
      }),
      transformResponse: (responseData: IGatewayDataTable[]) => {
        return responseData.map((item) => ({
          ...item,
          value: [],
        }))
      },
      providesTags: (result) =>
        result
          ? result.map(({ id }) => ({ type: "Gateway" as const, id }))
          : [{ type: "Gateway", id: "LIST" }],
    }),
    updateGatewayName: build.mutation<void, { id: number; name: string }>({
      query: ({ id, name }) => ({
        url: `/v1/gateways/prov/${id}`,
        method: "PATCH",
        body: {
          id,
          name,
        },
      }),
      async onQueryStarted({ id, name }, { dispatch, queryFulfilled }) {
        // Update the cache manually, without requerying
        const patchResult = dispatch(
          gatewaysApi.util.updateQueryData(
            "fetchGateways",
            undefined,
            (draft) => {
              const gateway = draft.find((g) => g.id === id)
              if (gateway) {
                gateway.name = name
              }
            }
          )
        )
        try {
          await queryFulfilled // Confirm the update
        } catch {
          patchResult.undo() // Undo the change in case of error
        }
      },
    }),
  }),
})

export const { useFetchGatewaysQuery, useUpdateGatewayNameMutation } =
  gatewaysApi
