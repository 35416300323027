import { AdlAlarm } from "./entities"
import { ChartLineUp, ThermometerSimple } from "phosphor-react"
import { ReactComponent as Threshold } from "../../../assets/Threshold.svg"
import theme from "../../../styles/theme"
import { toast } from "react-toastify"
import { ToastAlarm } from "../../../components/ui/ToastAlarm"

export const emitAlarm = ({
  title,
  backgroundColor,
  icon,
  content,
}: {
  title: string
  backgroundColor: string
  icon: JSX.Element
  content: Record<string, string>
}) => {
  toast(
    () => (
      <ToastAlarm
        backgroundColor={backgroundColor}
        icon={icon}
        title={title}
        content={content}
      />
    ),
    {
      autoClose: false,
      className: "toast-adl",
      style: {
        backgroundColor,
      },
    }
  )

  const alarmSound = new Audio(require("../../../assets/warning.mp3"))

  alarmSound.play().catch((error) => console.log(error))

  setTimeout(() => {
    alarmSound.pause()
  }, 3000)
}

export const NtcThresholdIcon = <ThermometerSimple weight='duotone' size={45} />

export const AdlAlarmIcon = (
  <ChartLineUp weight='duotone' size={60} color={theme.palette.gray[50]} />
)

export const FilterdThresholdIcon = (
  <Threshold width={500} height={500} stroke={theme.palette.gray[50]} />
)

export const getADLAlarmContent = (data: AdlAlarm): Record<string, string> => {
  if (data.intensity === 1) {
    return {
      Descrição: "Anomalia identificada",
      Intensidade: data.intensity.toString(),
      Local: data.sensorName,
      Data: new Date(data.time).toLocaleString("pt-BR"),
    }
  }

  return {
    Descrição: "Aumento da intensidade do alerta",
    Intensidade: data.intensity.toString(),
    Local: data.sensorName,
    Data: new Date(data.time).toLocaleString("pt-BR"),
  }
}
