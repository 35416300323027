import { AlarmsLogsPaginated } from "../../entities/alarmsLogsPaginatedDTO"

import {
  AlarmsLogsContainer,
  IconAndDescriptionContainer,
  TextContainer,
} from "./styles"

import { ReactComponent as SensorIdSVG } from "../../../../assets/SensorID_icon.svg"
import { ReactComponent as AlarmSVG } from "../../../../assets/alarms.svg"
import { ReactComponent as DateSVG } from "../../../../assets/date_icon.svg"
import { Tooltip } from "../Tooltip"
import { formatedDate } from "../../../../utils/date"
import { cleanAndPadSerialNumber } from "../../../../utils/cleanAndPadSerialNumber"
import { SensorConfig } from "../../../../store/features/sensors/sensors.interfaces"

const AlarmsInfo = ({
  alarmLog,
  configuredSensors,
}: {
  alarmLog: AlarmsLogsPaginated
  configuredSensors: SensorConfig[]
}): JSX.Element => {
  const _formatSerialNumberAndName = (
    alarmLog: AlarmsLogsPaginated
  ): string => {
    const find = configuredSensors.find((sensor) =>
      sensor.serialNumber.includes(alarmLog.productionSerialNumber)
    )

    if (!find) return alarmLog.productionSerialNumber

    return find.name
      ? find.name
      : `S${cleanAndPadSerialNumber(find.serialNumber)}`
  }

  return (
    <AlarmsLogsContainer key={alarmLog.date}>
      <IconAndDescriptionContainer style={{ width: "30%" }}>
        <SensorIdSVG width={"20px"} />
        <Tooltip title='Nº de série - Nome do sensor'>
          <TextContainer>{_formatSerialNumberAndName(alarmLog)}</TextContainer>
        </Tooltip>
      </IconAndDescriptionContainer>

      <IconAndDescriptionContainer style={{ width: "56%" }}>
        <AlarmSVG width={"16px"} />
        <Tooltip title='Mensagem de alerta'>
          <TextContainer>{alarmLog.message}</TextContainer>
        </Tooltip>
      </IconAndDescriptionContainer>

      <IconAndDescriptionContainer>
        <DateSVG width={"20px"} />
        <Tooltip title='Data e hora do alerta'>
          <TextContainer>{formatedDate(alarmLog.date)}</TextContainer>
        </Tooltip>
      </IconAndDescriptionContainer>
    </AlarmsLogsContainer>
  )
}

export default AlarmsInfo
